import React from 'react'
import Layout from 'components/Layout'
import AppStateProvider from './../state'
import Hero from '../components/Hero'
import heroImg from '../assets/images/board-meetroom.jpg'
import { Container } from 'components/PageContainer'
import './styles/contrib.scss'
import TeamMember from 'src/components/TeamMember'
import membersJSON from '../assets/data/board-members.json'

function RootIndex() {

  return (
    <AppStateProvider>
      <Layout title="Contributors | SwiftDoc">
        <div className="margin-container">
          <Hero
            heroImg={heroImg}
            title="SwiftDoc board of directors and team"
            subTitle=""
            pageName="Contributors"
          />
          <div className="aboutBgWhite">
            <Container>
              <p className="hero-blurb">
                Our Board of Directors and Management Team are highly experienced professionals with Legal, Medical, IT, Digital Marketing, Accounting and Recruitment expertise.
              </p>
            </Container>
          </div>
          <div className="contribBgGrey">
            <Container>
                {membersJSON && membersJSON.map((member, i) => 
              <div key={i} className="memberSection">
                <TeamMember teamMember={member} imageSide={i % 2 === 0 ? "right" : "left"} page="about" useStaticImage={true} />
              </div>  
              )}
            </Container>
          </div>
        </div>
      </Layout>
    </AppStateProvider>
  )
}

export default RootIndex
