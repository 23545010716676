import React from 'react'
import { Container } from '../PageContainer'
import parse from 'html-react-parser'


import './teamMember.scss'
function TeamMember({ teamMember, imageSide, page, useStaticImage=false }) {

  const image = useStaticImage ? require(`../../assets/board/${teamMember.memberBubblePhoto}`) : null;

  return (
    <Container>
      <div
        className={
          'bgWhiteTeamMember' + ' bgWhite' + imageSide + ' pageStyle' + page
        }
      >
        <div className={'textSide' + ' ' + 'pageStyle' + page}>
          <div className="memberName">
            <h1>{teamMember.memberName}</h1>
          </div>
          <div className="memberTitle">
            <p>{teamMember.memberRole}</p>
          </div>
          <div className="memberParagraph">
            {teamMember.memberParagraphs.map((paragraph, index) => (
              (paragraph && <p key={index}>{parse(paragraph)}</p>)
            ))}
          </div>
        </div>
        <div
          className={
            'imageSide' + ' imageSide' + imageSide + ' pageStyle' + page
          }
        >
          <div className="bubbleImage">
            {!useStaticImage && <img src={teamMember.memberBubblePhoto} alt={`${teamMember.memberName} photo`} />}
            {useStaticImage && image && <img src={image.default} alt={`${teamMember.memberName} photo`} />}
          </div>
        </div>
      </div>
    </Container>
  )
}

export default TeamMember
